/**
 * HeroArticle
 */

import React from 'react';
import classNames from 'classnames';
import {kebabCaseToPascalCase} from 'utils';
import { sanitizeLineBreaks } from 'utils/Html';
import Button from 'Components/Button';
import BackgroundImage from 'Components/BackgroundImage';
import s from './HeroArticle.module.scss';

const HeroArticle = ({
    label,
    title,
    text,
    image = {},
    genres = [],
    formattedPublishedAt = '',
    fullwidthImage = false,
    link,
    icon,
    iconName,
    textColor,
    backgroundColor,
    backgroundColorHex,
}) => {
    const sanitizedTitle = sanitizeLineBreaks(title);

    const hasImage = image && image.src && image.src.length > 0;
    const photographer = hasImage ? image.photographer : null;

    const hasTextColor = !iconName && !backgroundColor && textColor;
    const bgIsLight = backgroundColor && backgroundColor === 'yellow';
    const bgIsDark = (backgroundColor && backgroundColor !== 'yellow') || iconName;
    const isBlackTextColor = (hasTextColor && textColor === 'black') || bgIsLight;
    const isWhiteTextColor = (hasTextColor && textColor === 'white') || bgIsDark;

    const style = {};
    let buttonModifier = null;
    if(backgroundColorHex && !iconName && !backgroundColor) {
        style.backgroundColor = backgroundColorHex;
    }
    if(isWhiteTextColor) {
        buttonModifier = 'White';
    }

    const iconModifier = kebabCaseToPascalCase(iconName);
    const bgModifier = kebabCaseToPascalCase(backgroundColor);

    const classes = classNames(
        s['HeroArticle'],
        {[s['HeroArticle--HasImage']]: hasImage },
        {[s['HeroArticle--FullWidthImage']]: hasImage && fullwidthImage },
        {[s[`HeroArticle--${iconModifier}`]]: iconName && !icon },
        {[s['HeroArticle--Agenda2030']]: iconName && !icon },
        {[s[`HeroArticle--Background${bgModifier}`]]: !iconName && backgroundColor },
        {[s['HeroArticle--BgHex']]: !iconName && !backgroundColor && backgroundColorHex },
        {[s['HeroArticle--Icon']]: icon || iconName},
        {[s['HeroArticle--BlackText']]: isBlackTextColor},
        {[s['HeroArticle--WhiteText']]: isWhiteTextColor},
    );

    const iconClasses = classNames(
        s['HeroArticle__Icon'],
        {[s[`HeroArticle__Icon--${iconModifier}`]]: iconName && !icon },
    );

    return (
        <div className={classes} style={style}>
            <div className={s['HeroArticle__Container']}>
                {hasImage && (
                    <div className={s['HeroArticle__ImageContainer']}>
                        <BackgroundImage
                            image={image}
                            className={s['HeroArticle__Image']}
                        />
                        {photographer &&
                        <p className={s['HeroArticle__Photographer']}>Bild: {photographer}</p>
                        }
                    </div>
                )}
                <div className={s['HeroArticle__Layout']}>
                    {(icon || iconName) &&
                        <div className={iconClasses}>
                            {icon &&
                                <img
                                    src={icon.src}
                                    alt={icon.alt}
                                    width={icon.with}
                                    height={icon.height}
                                    title={icon.title}
                                />
                            }
                        </div>
                    }
                    <div className={s['HeroArticle__Content']}>
                        {label &&
                            <div className={s['HeroArticle__Label']}>{label}</div>
                        }
                        {title && (
                            <h1
                                className={s['HeroArticle__Title']}
                                dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
                            />
                        )}
                        <div className={s['HeroArticle__MetaContiner']}>
                            {formattedPublishedAt && (
                                <span className={s['HeroArticle__Date']}>{formattedPublishedAt}</span>
                            )}
                            {!!genres && !!genres.length &&
                            <ul className={s['HeroArticle__Tags']}>
                                {genres.map((genre, index) => <li className={s['HeroArticle__Tag']} key={index}>
                                    {genre.name}
                                </li>)}
                            </ul>
                            }
                        </div>
                        {text && (
                            <p className={s['HeroArticle__Preamble']}>{text}</p>
                        )}

                        {link && (
                            <div className={s['HeroArticle__Button']}>
                                <Button
                                    {...link}
                                    modifier={buttonModifier}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroArticle;
